import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const DescrComponent = () => {
  const { t } = useTranslation();

  return (
    <SWrapper>
      <h3 dangerouslySetInnerHTML={{ __html: t('discount.title') }} />

      <Box1>
        <h4>{t('discount.classic.title')}</h4>
        <p dangerouslySetInnerHTML={{ __html: t('discount.classic.text') }} />
      </Box1>
      <h4>{t('discount.3d.title')}</h4>
      <p dangerouslySetInnerHTML={{ __html: t('discount.3d.text1') }} />
      <p dangerouslySetInnerHTML={{ __html: t('discount.3d.text2') }} />
      <p dangerouslySetInnerHTML={{ __html: t('discount.3d.text3') }} />
      <p dangerouslySetInnerHTML={{ __html: t('discount.3d.text4') }} />

      <p>{t('discount.text')}</p>
    </SWrapper>
  );
};

export default DescrComponent;

const SWrapper = styled.div`
  margin-bottom: 24px;

  h3 {
    margin-bottom: 15px;
  }

  h3 > span {
    color: ${(p) => p.theme.colors.accentColor};
  }

  h4 {
    margin-bottom: 8px;
  }

  p > span {
    font-weight: 500;
  }

  p:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const Box1 = styled.div`
  margin-bottom: 20px;
`;
