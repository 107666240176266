/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { clearModelId, findModelForId, populateBrand } from '../../redux/filter/filter';
import { orderRug, updateSchemeCarPrices } from '../../redux/constructor/constructorAction';
import { addHeel, addBrandLabel, popelateInitialState, handlePulse } from '../../redux/constructor/constructor';
import { onError, onSuccess } from '../../components/notification/toastMessage';
import {
  FlexBox,
  FlexPrice,
  Section,
  SelectInputItem,
  SelectItem,
  SelectedList,
  StyledBtn,
  StyledContentWrapper,
  StyledLine,
  StyledPriceBox,
  StyledPriceText,
  StyledSelect,
  StyledSwiperWrapper,
  TitleOpyion,
  BorderImg,
  StyledLink,
  ShadowBox,
  StyledHeelInput,
  StyledHeelLabel,
  PositionRugWrapper,
  PositionREdgeWrapper,
} from './Constructor.styled';

import { SCHEME_CAR, SCHEME_VAN, TYPE_OF_BOART, TYPE_OF_MATERIAL, TYPE_OF_RUG } from '../../data/optionsConstructor';
import { HiddenBox, HiddenBox1_768, HiddenBox2, HiddenBox2_768 } from '../../components/common/HiddenBox/HiddenBox';
import Container from '../../components/common/Container/Container';
import TitleH2 from '../../components/common/TitleH2/TitleH2';
import SalonSchemeList from '../../components/constructorComponents/SalonSchemeList/SalonSchemeList';
import TypeMaterialList from '../../components/constructorComponents/TypeMaterialList/TypeMaterialList';
import TypeRugList from '../../components/constructorComponents/TypeRugList/TypeRugList';
import ColorRugItem from '../../components/constructorComponents/ColorRugItem/ColorRugItem';
import EdgeColorItem from '../../components/constructorComponents/EdgeColorItem/EdgeColorItem';
import AccessoriesList from '../../components/constructorComponents/AccessoriesList/AccessoriesList';
import ConstructorImage from '../../components/constructorComponents/ConstructorImage/ConstructorImage';
import Spinner from '../../components/common/Spinner/Spinner';
import DescrComponent from '../../components/constructorComponents/DescrComponent/DescrComponent';
import { scroller } from 'react-scroll';
import useToggle from '../../hooks/useToggle';
import ModalBasket from '../Basket/ModalBasket/ModalBasket';
// import { fetchCatalog } from '../../redux/filter/action';

export const handleScrollToEquipment = () => {
  scroller.scrollTo('scheme-section', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
  });
};

const Constructor = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { close, open, isOpen } = useToggle();
  const { titleKey, modelId } = useParams(); //получаю id модели
  const { modelForId, loading, error } = useSelector((state) => state.filter); //получаю название бренда

  const {
    totalPrice,
    salonScheme,
    currentImg,
    colorRugSet,
    colorEdgeSet,
    schemeSet,
    heel,
    namePlate,
    brandLabel,
    pulse,
  } = useSelector((state) => state.constructorRug);
  const [transSchemeVan, setTransSchemeVan] = useState();

  const processSchemeCar = (scheme) => {
    if (!Array.isArray(scheme)) {
      return []; // Возвращаем пустой массив, если данные некорректные
    }

    // Обрабатываем массив, добавляем переводы для цен
    return scheme.map((item) => ({
      ...item,
      price: t(`constructor.shameListPrice.${item.value}`), // Переводим цену через i18n
    }));
  };

  const priceHeel = t(`constructor.priceHeel`); //цена на подпятник

  const handleChange = (selectValue) => {
    const selectedOption = namePlate.find((option) => selectValue === option.value);

    const prices = t('constructor.brandLabelPrice', { returnObjects: true });

    if (selectedOption) {
      // Обновляем цену в зависимости от текущего языка
      const updatedOption = {
        ...selectedOption,
        price: prices[selectedOption.value] || 0, // получаем цену из JSON
      };

      // Отправляем обновленные данные в Redux
      dispatch(addBrandLabel(updatedOption));
    } else {
      dispatch(addBrandLabel({ value: 'item0', price: 0 }));
    }
  };

  const submitBasket = () => {
    try {
      if (!currentImg) {
        onError(`${t(`constructor.error3`)}`);
      } else if (salonScheme.length === 0) {
        onError(`${t(`constructor.error1`)}`);

        dispatch(handlePulse('pelse'));
        handleScrollToEquipment();
      } else {
        open();
        dispatch(orderRug());
        // onSuccess(`${t(`constructor.success`)}`);
      }
    } catch (error) {
      onError(`${t(`constructor.error2`)}`);
    }
  };

  useEffect(() => {
    const id = `${titleKey}?id=${modelId}`;
    if (modelId) {
      dispatch(findModelForId(id)); //отправляю название бренда в диспатч
    } else if (!modelId) {
      navigate('/constructor'); // переадресация на главную, если modelId отсутствует
    }
  }, [dispatch, modelId, navigate, modelForId, titleKey]);

  useEffect(() => {
    if (modelForId) {
      const { img, title, titleKey } = modelForId;
      const translatedPrices = t('constructor.additionalPrices', { returnObjects: true });
      const processedScheme = processSchemeCar(SCHEME_CAR);
      setTransSchemeVan(processSchemeCar(SCHEME_VAN));

      const currentModelImg = img ? img : '/images/catalog/models/placeholder-250x150.png';
      const currentTitle = title ? title : t(`constructor.titleNotFound`);

      dispatch(popelateInitialState({ currentModelImg, currentTitle, titleKey, processedScheme, translatedPrices }));
    } else {
      // Если modelForId не определен, можно задать значения по умолчанию
      dispatch(
        popelateInitialState({
          currentModelImg: '/images/catalog/models/placeholder-250x150.png',
          currentTitle: t(`constructor.titleNotFound`),
          titleKey: '',
        })
      );
    }
  }, [dispatch, modelForId, navigate, i18n.language]);

  useEffect(() => {
    return () => {
      dispatch(
        popelateInitialState({
          currentModelImg: '',
          currentTitle: '',
          titleKey: '',
        })
      );

      dispatch(clearModelId());
      dispatch(handlePulse(''));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!modelForId || modelForId.length === 0) {
      // dispatch(fetchCatalog());
      dispatch(populateBrand());
    }
  }, [dispatch, modelForId]);

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error}</div>;
  if (!modelForId || !colorRugSet || !colorEdgeSet) return <Spinner />;

  return (
    <Section>
      <Container>
        <FlexBox>
          <HiddenBox>
            {/* ВИЗУАЛИЗАЦИЯ КАРТИНОК */}
            <StyledSwiperWrapper>
              <ConstructorImage />
              <p style={{ marginBottom: '20px' }}>{t(`constructor.imgText`)}</p>
              <DescrComponent />
            </StyledSwiperWrapper>
          </HiddenBox>

          <StyledContentWrapper>
            {/* ЗАГОЛОВОК */}
            <div>
              {modelId ? (
                <TitleH2 constr="constr">{t(`models.modelsTitles.${titleKey}`)}</TitleH2>
              ) : (
                <TitleH2>{t(`constructor.titleStatik`)}</TitleH2>
              )}

              <StyledLine id="scheme-section" margin="12px 0 24px 0" />

              {/* КАРТИНКА мобильная */}
              <HiddenBox2>
                <StyledSwiperWrapper>
                  <ConstructorImage />
                </StyledSwiperWrapper>
              </HiddenBox2>

              {/* КОМПЛЕКТАЦИЯ */}
              <div>
                <TitleOpyion pulse={pulse}>{t(`constructor.equipment`)}</TitleOpyion>
                {modelForId.body === 'VAN' ? (
                  <SalonSchemeList scheme={transSchemeVan} />
                ) : (
                  // <SalonSchemeList scheme={SCHEME_VAN} />
                  <SalonSchemeList scheme={schemeSet} />
                )}
              </div>

              {/* ТИП МАТЕРИАЛА  РОМБ ИЛИ СОТА */}
              <StyledLine margin="12px 0 24px 0" />
              <div>
                <TitleOpyion>{t(`constructor.typeM`)}</TitleOpyion>
                <TypeMaterialList type={TYPE_OF_MATERIAL} />
              </div>

              {/* ТИП КОВРИКА ОБЫЧНЫЙ ИЛИ 3Д... / также выбор бортика 5-10см*/}
              <StyledLine margin="12px 0 24px 0" />
              <div>
                <TitleOpyion>{t(`constructor.typeRug`)}</TitleOpyion>
                <TypeRugList RUG={TYPE_OF_RUG} BOARD={TYPE_OF_BOART} />
              </div>

              {/* ЦВЕТ КОВРИКА */}
              <PositionRugWrapper>
                <TitleOpyion>{t(`constructor.colorRug`)}</TitleOpyion>
                <ColorRugItem />
              </PositionRugWrapper>
              <StyledLine mobile="mobile" margin="0 0 24px 0" />

              {/* ЦВЕТ КАНТИКА */}
              <PositionREdgeWrapper>
                <TitleOpyion>{t(`constructor.colorEdge`)}</TitleOpyion>
                <EdgeColorItem />
              </PositionREdgeWrapper>

              <StyledLine margin="0 0 24px 0" />

              {/* ПОДПЯТНИК */}
              <SelectedList>
                <SelectInputItem>
                  <p>{t(`constructor.heel`)}</p>
                  <BorderImg>
                    <img src="/images/constructor/options/pod-icon.png" alt={t(`constructor.heel`)} />
                  </BorderImg>

                  <StyledHeelInput
                    type="checkbox"
                    name="heel"
                    id="heel"
                    checked={heel}
                    onChange={(e) =>
                      dispatch(
                        addHeel({
                          checked: e.target.checked,
                          price: priceHeel,
                        })
                      )
                    }
                  />
                  <StyledHeelLabel mobile="mobile" padding="12px 70px" htmlFor="heel">
                    {t(`constructor.addBtn`)} ({priceHeel} {t(`accessories.money`)})
                  </StyledHeelLabel>
                </SelectInputItem>

                {/* ШИЛЬДЫК */}
                <SelectItem>
                  <p>{t(`constructor.brandLabel`)}</p>
                  <BorderImg>
                    <img src="/images/constructor/options/brand-icon.png" alt="Шильдик" />
                  </BorderImg>

                  <StyledSelect value={brandLabel} onChange={(e) => handleChange(e.target.value)}>
                    {namePlate.map(({ value }) => {
                      return (
                        <option key={value} value={value}>
                          {t(`constructor.brandLabelSelect.${value}`)}
                        </option>
                      );
                    })}
                  </StyledSelect>
                </SelectItem>
              </SelectedList>
            </div>
          </StyledContentWrapper>
        </FlexBox>
      </Container>

      <StyledPriceBox>
        <HiddenBox2_768>
          <ShadowBox />
        </HiddenBox2_768>
        <Container>
          {/* ОБЩЯЯ СУММА */}
          <FlexPrice>
            <div>
              <StyledPriceText>{t(`constructor.price`)}</StyledPriceText>
            </div>
            <FlexPrice>
              <StyledPriceText>
                {totalPrice} {t('accessories.money')}
              </StyledPriceText>
              <HiddenBox1_768>
                <StyledBtn margin="0 0 0 100px" padding="20px 97px" price="price" onClick={submitBasket}>
                  {t(`constructor.btn`)}
                </StyledBtn>
              </HiddenBox1_768>
            </FlexPrice>
          </FlexPrice>

          <StyledLine margin="12px 0 60px 0" mobile="12px 0 0 0" />
          <HiddenBox2_768>
            <StyledBtn price="price" margin="24px 0 40px 0" onClick={submitBasket}>
              {t(`constructor.btn`)}
            </StyledBtn>
          </HiddenBox2_768>

          {/* СПИСОК АКСЕСУАРОВ КОТОРЫЕ ПОКУПАЮТ */}
          <FlexPrice margin="0 0 24px 0">
            <div>
              <StyledPriceText>{t(`constructor.buy`)}</StyledPriceText>
            </div>
            <FlexPrice>
              <HiddenBox1_768>
                <StyledLink to="/accessories" padding="16px 29px" price="price">
                  {t(`constructor.accessories`)}
                </StyledLink>
              </HiddenBox1_768>
            </FlexPrice>
          </FlexPrice>

          <AccessoriesList />

          <HiddenBox2_768>
            <StyledLink to="/accessories" price="price">
              {t(`constructor.accessories`)}
            </StyledLink>
          </HiddenBox2_768>
        </Container>
      </StyledPriceBox>
      <Container>
        <HiddenBox2>
          <div style={{ marginTop: '24px' }}>
            <DescrComponent />
            <p style={{ marginBottom: '20px' }}>{t(`constructor.imgText`)}</p>
          </div>
        </HiddenBox2>
      </Container>
      {isOpen && <ModalBasket close={close} />}
    </Section>
  );
};

export default Constructor;
