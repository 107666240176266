import { addRugOrder } from '../basket/basket';
// передача данных в корзину

export const orderRug = () => (dispatch, getState) => {
  const state = getState().constructorRug;
  const {
    imgModel,
    title,
    salonScheme,
    typeM,
    colorRug,
    colorEdge,
    heel,
    brandLabel,
    typeBoart,
    typeRug,
    titleKey,
    totalPrice,
  } = state;

  const order = {
    imgModel,
    title,
    salonScheme,
    typeM,
    colorRug,
    colorEdge,
    heel,
    brandLabel: brandLabel || '-',
    typeBoart: typeBoart || '-',
    typeRug,
    titleKey,
    price: totalPrice,
  };

  dispatch(addRugOrder(order));
};
