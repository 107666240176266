/* --------------------- компонент перевода для корзины --------------------- */
const translateOrderValues = (order, t) => {
  const {
    salonScheme,
    typeM,
    typeRug,
    typeBoart,
    colorRug,
    colorEdge,
    brandLabel,
    color,
    selectedSideСolor,
    selectedCenterColor,
    selectedInsertColor,
    selectedThreadColor,
  } = order;

  const colorsCover = [
    { title: t(`accessoriesCard.select7.title`), colorValue: selectedSideСolor },
    { title: t(`accessoriesCard.select8.title`), colorValue: selectedCenterColor },
    { title: t(`accessoriesCard.select9.title`), colorValue: selectedInsertColor },
    { title: t(`accessoriesCard.select10.title`), colorValue: selectedThreadColor },
  ];

  const valueScheme = salonScheme?.map(({ value }) => t(`constructor.shameList.${value}`));
  const translatedValues = valueScheme?.map((value) => value);
  const translTypeM = t(`constructor.typeMList.${typeM}`);
  const translTypeRug = t(`constructor.typeRugTitle.${typeRug}`);
  const translTypeBoart = typeBoart === '-' ? '-' : t(`basket.typeBoart.${typeBoart}`);
  const translBrandLabel = t(`basket.brandLabel.${brandLabel}`);
  const translColorRug = t(`constructor.colorRugList.${colorRug}`);
  const translСolorEdge = t(`constructor.colorEdgeList.${colorEdge}`);
  const translСolor = t(`accessoriesCard.select3.${color}`);

  const translСolorCover = colorsCover.map(({ title, colorValue }) => {
    return { title, color: t(`accessoriesCard.select3.${colorValue}`) };
  });

  return {
    translatedValues,
    translTypeM,
    translTypeRug,
    translTypeBoart,
    translBrandLabel,
    translColorRug,
    translСolorEdge,
    translСolor,
    translСolorCover,
  };
};

export default translateOrderValues;
