import { createSlice } from '@reduxjs/toolkit';
import { CTALOG_COVER } from '../../data/dbCover';
const initialState = {
  coverId: '',
  imgCover: '',
  title: '',
  typeM: '', //тип материала ева\эко
  catalog: CTALOG_COVER,

  sideСolor: [],
  centerColors: [], // для центра
  insertColors: [], // для вставки
  threadColors: [], // для нитки

  selectedSideСolor: '', //цвет боковин
  selectedCenterColor: '', //цвет цента
  selectedInsertColor: '', //цвет вставки
  selectedThreadColor: '', //цвет нитки

  price: 0,
};

const coverSlice = createSlice({
  name: 'cover',
  initialState,
  reducers: {
    popelateCoverInitialState: (state, { payload }) => {
      const { id, price, material, title, colors, img, insert, center } = payload;
      state.coverId = id;
      state.imgCover = img;
      state.title = title;
      state.typeM = material;
      state.price = price;

      if (colors.length > 0) {
        state.sideСolor = colors[0].sideСolor || [];
        state.centerColors = (center && colors[0].centerСolor) || [];
        state.insertColors = (insert && colors[0].insertСolor) || [];
        state.threadColors = colors[0].threadСolor || [];
      }

      state.selectedSideСolor = state.sideСolor[0].value;
      state.selectedCenterColor = center && state.centerColors[0].value;
      state.selectedInsertColor = insert && state.insertColors[0].value;
      state.selectedThreadColor = state.threadColors[0].value;
    },

    // боковины
    setSelectedSideСolor: (state, { payload }) => {
      state.selectedSideСolor = payload;
    },

    // центр
    setSelectedCenterColor: (state, { payload }) => {
      state.selectedCenterColor = payload;
    },

    // вставка
    setSelectedInsertColor: (state, { payload }) => {
      state.selectedInsertColor = payload;
    },

    // нитки
    setSelectedThreadColor: (state, { payload }) => {
      state.selectedThreadColor = payload;
    },

    cleanState: (state) => {
      state.imgAcc = '';
      state.title = '';
      state.typeM = '';
      state.price = 0;
    },
  },
});

export const {
  popelateCoverInitialState,
  setSelectedSideСolor,
  setSelectedCenterColor,
  setSelectedInsertColor,
  setSelectedThreadColor,
  cleanState,
} = coverSlice.actions;

export const coverReducer = coverSlice.reducer;
/* ---------------------------- распарсить обьект --------------------------- */
function decoder(name, value) {
  const originalObject = JSON.parse(JSON.stringify(value));
  console.log(name, originalObject);
}
