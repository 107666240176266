/* eslint-disable jsx-a11y/alt-text */
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { handleSalonScheme } from '../../../redux/constructor/constructor';
import { BorderImg, CheckboxInput, EquipmentItem, EquipmentList } from './SalonSchemeList.styled';

const SalonSchemeList = ({ scheme }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const equipmentCar = useSelector((state) => state.constructorRug.salonScheme);
  const { buttonDisabled } = useSelector((state) => state.constructorRug);

  const handleCheckboxChange = (value, price) => {
    dispatch(handleSalonScheme({ value, price }));
  };

  if (!equipmentCar) return null;

  return (
    <EquipmentList>
      {scheme?.map(({ img, value, price }) => {
        const isChecked = equipmentCar.some((item) => item.value === value);
        return (
          <EquipmentItem key={value}>
            <CheckboxInput
              type="checkbox"
              checked={isChecked}
              id={value}
              name="equipment"
              onChange={() => handleCheckboxChange(value, price)}
              disabled={buttonDisabled && !isChecked}
            />

            <label htmlFor={value} title={t(`constructor.shameList.${value}`)}>
              <BorderImg disabled={buttonDisabled && !isChecked}>
                <img src={img} />
              </BorderImg>
              <p>
                {price} {t('accessories.money')}
              </p>
            </label>
          </EquipmentItem>
        );
      })}
    </EquipmentList>
  );
};

export default SalonSchemeList;
